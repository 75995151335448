.built {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 80px;
}

.built-top {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
}

.built-top__title {
    margin: 0;
    font-size: 52px;
    font-weight: 700;
    text-align: left;
}

.built-title-link {
    cursor: pointer;
    text-decoration: none;
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    color: rgba(30, 30, 30, 0.5);
    transition: color 0.3s;
}

.built-title-link:hover {
    color: #1e1e1e;
}

.built-grid {
    max-width: 1440px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(326px, 1fr));
    gap: 20px;
}

.built-grid__item {
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.built-grid__item-link {
    text-decoration: none;
    color: #1e1e1e;
}

.built-grid__item-img-wrapper {
    position: relative;
    width: 100%;
    height: 500px;
    border-radius: 24px;
    overflow: hidden;
}

.complex__item-link::before {
    content: url('../../../images/main/icon.svg');
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: -100px;
    margin: auto;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    pointer-events: none;
    border-radius: 100px;
    background: rgba(0, 0, 0, 0.5);
    transition: bottom 0.3s ease;
    backdrop-filter: blur(2px);
}

.complex__item-link:hover::before {
    bottom: 25px;
}

.built-grid__item-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: 0.3s;
}

.built-grid__item-img:hover {
    transform: scale(1.3);
}

.built-grid__item-text {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.built-grid__item-text {
    font-size: 20px;
    font-weight: 700;
    text-align: left;
}

.built-grid__item-text-bottom {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #3d3d3d;
}

.built-grid__item-statistic {
    position: absolute;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
}

.built-grid__item-info {
    width: 126px;
    height: 32px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    background: rgba(0, 0, 0, 0.5);
    color: var(--basic-white);
    font-size: 13px;
    font-weight: 500;
    text-align: left;
}

.built-grid__item-status {
    color: #76ba41;
    font-size: 24px;
    height: inherit;
}

.built-grid__link {
    padding: 17px 81px;
    display: none;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    border-radius: 100px;
    background-color: #ffd737;
    color: #000000;
    text-decoration: none;
}

@media (max-width: 900px) {
    .complex__item-link::before {
        display: none;
    }

    .built-grid__item-img:hover {
        transform: none;
    }
}

@media (max-width: 750px) {
    .built-title-link {
        display: none;
    }
    .built-grid__link {
        display: block;
    }

    .built-grid__item-img-wrapper {
        height: 406px;
    }
}

@media (max-width: 500px) {
    .built {
        gap: 32px;
    }

    .built-top__title {
        font-size: 42px;
    }

    .built-grid__item-text {
        flex-wrap: wrap;
        column-gap: 70px;
        row-gap: 8px;
    }

    .built-grid {
        gap: 40px;
    }

    .built-grid__item-img-wrapper {
        height: 404px;
        border-radius: 40px;
    }
}
